import '../scss/style.scss';
import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';
/* import 'swiper/scss';
import 'swiper/scss/bundle';
import 'swiper/scss/navigation'; */

document.addEventListener('DOMContentLoaded', () => {


    var isActiveMenu = false;

    function doMenu() {
        if (isActiveMenu) {
            document.querySelector("#responsive-menu").style.display = "none";
            document.querySelector("#img-menu").src = "/wp-content/uploads/2023/12/menu-open.png";
            isActiveMenu = false;
        }
        else {
            document.querySelector("#responsive-menu").style.display = "flex";
            document.querySelector("#img-menu").src = "/wp-content/uploads/2023/12/menu-close.png";
            isActiveMenu = true;
        }
    }

    document.getElementById("doMenu").addEventListener("click", doMenu);

    if (upcomingEvents) {
        let pictureUrlEvent = document.querySelector(".swiper-events .swiper-wrapper > div:first-child") ? document.querySelector(".swiper-events .swiper-wrapper > div:first-child").getAttribute("data-picture") : "";
        document.getElementById('comingevents-image').style.backgroundImage = "url('" + pictureUrlEvent + "')";
        document.getElementById('comingevents-image-img').setAttribute('src', pictureUrlEvent);
    }
    let pictureUrlOldEvent = document.querySelector(".swiper-oldevents .swiper-wrapper > div:first-child").getAttribute("data-picture");
    document.getElementById('events-image').style.backgroundImage = "url('" + pictureUrlOldEvent + "')";
    document.getElementById('events-image-img').setAttribute('src', pictureUrlOldEvent);


    const swiperOldEvents = new Swiper('.swiper-oldevents', {
        // configure Swiper to use modules
        modules: [Navigation, Autoplay],
        speed: 400,
        spaceBetween: 100,
        autoplay: {
            delay: 5000,
        },
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    swiperOldEvents.on('slideChangeTransitionEnd', function () {
        let pictureUrl = document.querySelector(".swiper-oldevents .swiper-slide-active").getAttribute("data-picture");
        document.getElementById('events-image').style.backgroundImage = "url('" + pictureUrl + "')";
        document.getElementById('events-image-img').setAttribute('src', pictureUrl);
    });

    if (upcomingEvents) {
        const swiperEvents = new Swiper('.swiper-events', {
            // configure Swiper to use modules
            modules: [Navigation, Autoplay],
            speed: 400,
            spaceBetween: 100,
            autoplay: {
                delay: 5000,
            },
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        swiperEvents.on('slideChangeTransitionEnd', function () {
            let pictureUrl = document.querySelector(".swiper-events .swiper-slide-active").getAttribute("data-picture");
            document.getElementById('comingevents-image').style.backgroundImage = "url('" + pictureUrl + "')";
            document.getElementById('comingevents-image-img').setAttribute('src', pictureUrl);
        });
    }
});